<template>
  <div id="dashboard-content">
    <section id="profile-publisher">
      <h4 class="mb-4">Profil</h4>
      <div class="card p-3 border-0 shadow-sm mb-3">
        <ul class="nav nav-pills nav-fill" id="pills-tab" role="tablist">
          <li class="nav-item border">
            <a
              class="nav-link active d-flex align-items-center"
              id="pills-data-perusahaan-tab"
              data-toggle="pill"
              href="#pills-data-perusahaan"
              role="tab"
              aria-controls="pills-data-perusahaan"
              aria-selected="true"
            >
              <div
                style="width: 25px;height: 25px;"
                class="bg-white text-dark rounded-circle d-flex align-items-center justify-content-center mr-2"
              >
                1
              </div>
              Data Perusahaan
            </a>
          </li>
          <li class="nav-item pt-1">
            <hr width="200px" />
          </li>
          <li class="nav-item border">
            <a
              class="nav-link d-flex align-items-center"
              id="pills-data-direktur-tab"
              data-toggle="pill"
              href="#pills-data-direktur"
              role="tab"
              aria-controls="pills-data-direktur"
              aria-selected="false"
            >
              <div
                style="width: 25px;height: 25px;"
                class="bg-white text-dark rounded-circle d-flex align-items-center justify-content-center mr-2 border"
              >
                2
              </div>
              Data Direktur
            </a>
          </li>
          <li class="nav-item pt-1">
            <hr width="200px" />
          </li>
          <li class="nav-item border">
            <a
              class="nav-link d-flex align-items-center"
              id="pills-data-berkas-tab"
              data-toggle="pill"
              href="#pills-data-berkas"
              role="tab"
              aria-controls="pills-data-berkas"
              aria-selected="false"
            >
              <div
                style="width: 25px;height: 25px;"
                class="bg-white text-dark rounded-circle d-flex align-items-center justify-content-center mr-2 border"
              >
                3
              </div>
              Data Berkas
            </a>
          </li>
        </ul>
      </div>
      <div class="card p-3 border-0 shadow-sm">
        <form @submit.prevent="updateProfile()">
          <div class="tab-content border-0" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-data-perusahaan"
              role="tabpanel"
              aria-labelledby="pills-data-perusahaan-tab"
            >
              <h5 class="font-weight-bold mb-2">Data Perusahaan</h5>
              <p class="mb-4">Silahkan isi data-data terkait perusahaan</p>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="penerbit" class="form-label"
                      >Nama Penerbit / Pencetak <span class="text-danger">*</span></label
                    >
                    <input
                      id="penerbit"
                      type="text"
                      class="form-control"
                      placeholder="Masukan nama penerbit atau pencetak"
                      v-model="publisher.name"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="nib" class="form-label">NIB</label>
                    <input
                      id="nib"
                      type="text"
                      class="form-control"
                      placeholder="Masukan nomor induk berusaha"
                      v-model="publisher.nib"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="provinsi" class="form-label">Provinsi</label>
                    <select
                      v-model="publisher.province"
                      id="provinsi"
                      class="form-control"
                      required
                      @change="getCity($event)"
                    >
                      <option value="" disabled>Pilih ..</option>
                      <option
                        v-for="(province, index) of provinces"
                        :key="index"
                        :value="province.id"
                      >
                        {{ province.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="kota" class="form-label">Kota/Kab</label>
                    <select
                      required
                      class="form-control"
                      id="kota"
                      v-model="publisher.city"
                    >
                      <option value="" selected disabled>Pilih Kota</option>
                      <option
                        v-for="(city, index) in cities"
                        :key="index"
                        :value="city.id"
                        >{{ city.name }}</option
                      >
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="noTelp" class="form-label">No. Telepon <span class="text-danger">*</span></label>
                    <input
                      type="number"
                      id="noTelp"
                      class="form-control"
                      v-model="publisher.phone"
                      placeholder="Masukan no telepon"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="email" class="form-label">Email Perusahaan</label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">@</div>
                      </div>
                      <input
                        readonly
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="Masukan email perusahaan"
                        v-model="publisher.email"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="alamat" class="form-label">Alamat Perusahaan <span class="text-danger">*</span></label>
                    <textarea
                      id="alamat"
                      class="form-control"
                      v-model="publisher.address"
                      placeholder="Masukan alamat perusahaan"
                    ></textarea>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="alamatPusat" class="form-label">Alamat Kantor Pusat <span class="text-danger">*</span></label>
                    <textarea
                      id="alamatPusat"
                      class="form-control"
                      v-model="publisher.head_office"
                      placeholder="Masukan alamat kantor pusat"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-4" role="tablist">
                <button
                  type="button"
                  class="btn rounded-pill btn-secondary text-white"
                  @click="selectTab('#pills-data-direktur-tab')"
                >
                  Selanjutnya
                  <i class="fa fa-chevron-right"></i>
                </button>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-data-direktur"
              role="tabpanel"
              aria-labelledby="pills-data-direktur-tab"
            >
              <h5 class="font-weight-bold mb-2">Data Direktur</h5>
              <p class="mb-4">Silahkan isi data-data terkait direktur</p>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="direktur" class="form-label">Nama Direktur <span class="text-danger">*</span></label>
                    <input
                      id="direktur"
                      type="text"
                      class="form-control"
                      placeholder="Masukan nama direktur"
                      v-model="publisher.director_name"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="noHpDirektur" class="form-label">No. HP / Telepon Direktur <span class="text-danger">*</span></label>
                    <input
                      id="noHpDirektur"
                      type="number"
                      class="form-control"
                      placeholder="Masukan nomor telepon"
                      v-model="publisher.director_phone"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="emailDirektur" class="form-label">Email Direktur <span class="text-danger">*</span></label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">@</div>
                      </div>
                      <input
                        type="email"
                        class="form-control"
                        id="emailDirektur"
                        placeholder="Masukan email direktur"
                        v-model="publisher.director_email"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="contactPerson1" class="form-label">Nama Kontak Person <span class="text-danger">*</span></label>
                    <input
                      id="contactPerson1"
                      type="text"
                      class="form-control"
                      placeholder="Masukan nama kontak person"
                      v-model="publisher.contact_person"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="contactPerson" class="form-label">No Telp Kontak Person <span class="text-danger">*</span></label>
                    <input
                      id="contactPerson"
                      type="number"
                      class="form-control"
                      placeholder="Masukan nomor telepon kontak person"
                      v-model="publisher.contact_person_phone"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-4" role="tablist">
                <button
                  type="button"
                  class="btn rounded-pill btn-outline-secondary mr-3"
                  @click="selectTab('#pills-data-perusahaan-tab')"
                >
                  <i class="fa fa-chevron-left"></i>
                  Sebelumnya
                </button>
                <button
                  type="button"
                  class="btn rounded-pill btn-secondary text-white"
                  @click="selectTab('#pills-data-berkas-tab')"
                >
                  Selanjutnya
                  <i class="fa fa-chevron-right"></i>
                </button>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-data-berkas"
              role="tabpanel"
              aria-labelledby="pills-data-berkas-tab"
            >
              <h5 class="font-weight-bold mb-2">Data Berkas</h5>
              <p class="mb-4">Silahkan isi data-data terkait berkas</p>
              <div class="row d-flex align-items-center">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>
                      Salinan SIUP/NIB beserta NPWP disatukan dalam satu file
                      <span class="text-muted">(ukuran maks 10mb)</span>
                    </label>
                    <FileUploader
                      @select-file="setFile($event, 'siup')"
                      :uploaded="publisher.siup"
                      id="siup"
                    />
                    <small class="text-muted">Salinan SIUP/NIB beserta NPWP disatukan dalam satu file</small>
                  </div>
                  <button
                    v-if="!$store.state.loadUploadFile"
                    @click="uploadingFile('siup')"
                    id="uploadSIUP"
                    type="button"
                    class="btn btn-primary btn-block"
                  >
                    Upload
                  </button>
                  <button
                    type="button"
                    v-if="$store.state.loadUploadFile"
                    class="btn btn-primary btn-block"
                  >
                    <span class="spinner-border spinner-border-sm"></span>
                    Sedang diproses
                  </button>
                </div>
              </div>
              <!-- <div
                v-if="$store.state.messageStatusPublisher"
                class="alert alert-success mt-2"
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  @click="$store.state.messageStatusPublisher = false"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <span>
                  Terimakasih! Data berhasil diperbaharui. Untuk tahap
                  selanjutnya silahkan akses menu daftarkan buku atau izin
                  cetak.
                </span>
              </div> -->
              <div class="d-flex justify-content-end mt-4" role="tablist">
                <button
                  type="button"
                  class="btn rounded-pill btn-outline-secondary mr-3"
                  @click="selectTab('#pills-data-direktur-tab')"
                >
                  <i class="fa fa-chevron-left"></i>
                  Sebelumnya
                </button>
                <button
                  type="submit"
                  class="btn btn-secondary rounded-pill text-white"
                >
                  {{
                    $store.state.loadPage
                      ? "Sedang diproses.."
                      : "Submit Profil"
                  }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import { BASE_URL } from "../../../store";
import Swal from "sweetalert2";
import FileUploader from "@/components/global/dashboard/FileUploader.vue";
export default {
  components: { FileUploader },
  data() {
    return {
      API_KEY:
        "a0a39455c89dadd653f4a0f4a1748df3aa5c92448396881214a47d90547494fa",
      API_PROVINCE: "https://api.binderbyte.com",
      provinces: [],
      cities: [],
      passedValidation: false,
      publisher: {
        name: "",
        address: "",
        province: "",
        phone: "",
        email: "",
        head_office: "",
        city: "",
        director_name: "",
        director_phone: "",
        director_email: "",
        contact_person: "",
        contact_person_phone: "",
        siup: "",
        nib: "",
        kta_ikapi: "",
        surat_pengajuan: "",
        surat_pernyataan: "",
      },
      file: {
        siup: null,
        kta_ikapi: null,
        surat_pengajuan: null,
        surat_pernyataan: null,
      },
      loading: {
        pengajuan: false,
        pernyataan: false,
        kta: false,
        siup: false,
      },
      link: {
        pengajuan: "",
        pernyataan: "",
        siup: "",
        kta: "",
      },
      required: [
        {
          title: "data berkas",
          tabs: "#pills-data-berkas-tab",
          type: "file",
          input: ["siup"],
        },
        {
          title: "data direktur",
          tabs: "#pills-data-direktur-tab",
          type: "text",
          input: ["director_name", "director_phone", "director_email", "contact_person", "contact_person_phone"],
        },
        {
          title: "data perusahaan",
          tabs: "#pills-data-perusahaan-tab",
          type: "text",
          input: ["name", "phone", "address", "head_office"],
        },
      ],
    };
  },
  methods: {
    ...mapActions(["updatePublisherProfile", "uploadFile"]),
    async checkValidation() {
      let validationFailed = false;

      await Promise.all(
        this.required.map(async (data) => {
          await Promise.all(
            data.input.map(async (name) => {
              if (this.publisher[name] == "") {
                await Swal.fire({
                  icon: "warning",
                  title: `Silahkan lengkapi bagian ${data.title} yang wajib`,
                }).then((status) => {
                  if (status.isConfirmed) {
                    document.querySelector(data.tabs).click();
                    validationFailed = true;
                  }
                });
              } else {
                if (data.type == "file") {
                  if (this.publisher[name] !== this.link[name] || this.file[name]) {
                    await Swal.fire({
                      icon: "warning",
                      title: `Silahkan klik tombol unggah pada ${data.title} terlebih dahulu`,
                    }).then((status) => {
                      if (status.isConfirmed) {
                        document.querySelector(data.tabs).click();
                        validationFailed = true;
                      }
                    });
                  }
                }
              }
            })
          );
        })
      );

      // Memanggil metode lain setelah selesai looping data.input.map dan index melewati panjang this.required
      if (!validationFailed) {
          this.passedValidation = true;
      }
    },
    selectTab(id) {
      document.querySelector(id).click();
    },
    setFile(file, state) {
      this.file[state] = file;
    },
    selectFile(event) {
      let file = event.target.files[0];
      event.target.id == "pengajuan" && (this.file.surat_pengajuan = file);
      event.target.id == "pernyataan" && (this.file.surat_pernyataan = file);
      event.target.id == "siup" && (this.file.siup = file);
      event.target.id == "kta" && (this.file.kta_ikapi = file);
    },
    uploadingFile(state) {
      let file = null;

      // Validation check if file null
      if (this.file[state] !== null) {
        this.loading[state] = true;
        file = this.file[state];
      } else {
        Swal.fire({
          icon: "warning",
          title: "Berkas harus diisi",
        });
      }

      // Upload file to server
      if (file !== null) {
        this.uploadFile(file)
          .then((res) => {
            if (res.status == "success") {
              this.publisher[state] = res.file;
              this.link[state] = res.file;
              this.file[state] = null;
              Swal.fire({
                  icon: "success",
                  title: "Berhasil",
                  text: "Berhasil diunggah, selanjutnya silahkan klik tombol simpan"
                });
            } else {
              Swal.fire({
                icon: "error",
                imageUrl:
                  "https://ik.imagekit.io/8jggdaymrs/app.buku.kemdikbud.go.id/Done.png",
                imageAlt: "Done",
                title: "<h4>Gagal upload berkas</h4>",
                text: res.message,
              });
            }
          })
          .catch((err) => {
            if (err == 403) {
              Swal.fire({
                icon: "error",
                title: "<h4>Gagal upload berkas</h4>",
                text: "Ukuran file terlalu besar",
              });
            }
          })
          .finally(() => {
            this.loading.kta = false;
            this.loading.siup = false;
            this.loading.pengajuan = false;
            this.loading.pernyataan = false;
          });
      }
    },
    async updateProfile() {
      await this.checkValidation();
      if (this.passedValidation) {
        this.$store.state.loadPage = false;
        this.updatePublisherProfile(this.publisher);
        Swal.fire({
          imageUrl:
            "https://ik.imagekit.io/8jggdaymrs/app.buku.kemdikbud.go.id/Done.png",
          imageAlt: "Done",
          title: "<h4>Data profil Anda berhasil tersimpan</h4>",
          text: "Penambahan data telah berhasil tersimpan",
        })
        .then(status => {
          if(status.isConfirmed) {
            this.$router.push('/user/home')
          }
        });
      }
    },
    setValue(publisher) {
      this.publisher.name = publisher.name;
      this.publisher.province = publisher.province;
      this.publisher.city = publisher.city;
      this.publisher.address = publisher.address;
      this.publisher.phone = publisher.phone;
      this.publisher.nib = publisher.nib;
      this.publisher.head_office = publisher.head_office;
      this.publisher.director_name = publisher.director_name;
      this.publisher.director_phone = publisher.director_phone;
      this.publisher.director_email = publisher.director_email;
      this.publisher.contact_person = publisher.contact_person;
      this.publisher.contact_person_phone = publisher.contact_person_phone;
      this.publisher.surat_pengajuan = publisher.surat_perjanjian_penerbitan;
      this.publisher.surat_pernyataan = publisher.surat_perjanjian_hak_cipta;
      this.publisher.kta_ikapi = publisher.sertifikat_bnsp;
      this.publisher.siup = publisher.siup;

      // Set link state
      this.link.pengajuan = publisher.surat_perjanjian_penerbitan;
      this.link.pernyataan = publisher.surat_perjanjian_hak_cipta;
      this.link.kta = publisher.sertifikat_bnsp;
      this.link.siup = publisher.siup;
    },
    getCity(e) {
      axios({
        method: "get",
        url:
          this.API_PROVINCE +
          `/wilayah/kabupaten?api_key=${this.API_KEY}&id_provinsi=${e.target.value}`,
      }).then((res) => {
        this.cities = res.data.value;
      });
    },
  },
  created() {
    let user = JSON.parse(localStorage.getItem("user"));

    this.publisher.email = user.email;
  },
  mounted() {
    this.$store.state.messageStatusPublisher = false;
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}api/user/getPublisherProfile`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        this.setValue(res.data.result);
        axios({
          method: "get",
          url:
            this.API_PROVINCE +
            `/wilayah/kabupaten?api_key=${this.API_KEY}&id_provinsi=${res.data.result.province}`,
        }).then((res) => {
          this.cities = res.data.value;
        });
      });

    // Get Province
    axios({
      method: "get",
      url: this.API_PROVINCE + `/wilayah/provinsi?api_key=${this.API_KEY}`,
    }).then((res) => {
      this.provinces = res.data.value;
    });
  },
};
</script>

<style></style>
